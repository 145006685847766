
// import { Dialog, EventBus, EventBusEvents } from '@/event/EventBus'
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'

@Options({})
export default class CustomMoneyDialogView extends Vue {
    @Getter('device') device!: Device

    @Action('setCustomChip') setCustomChip!: (value: number) => void

    chipValue = ''
    closeDialog(): void {
        // EventBus.$emit(Dialog.CUSTOMMONEY, false)
        // EventBus.$emit(EventBusEvents.SHOW_DIALOGS, Dialog.CUSTOMMONEY)
        this.$emit('on-close')
    }

    confirm(): void {
        const amount = parseInt(this.chipValue)
        if (amount <= 0 || amount.toString() === 'NaN') return

        this.setCustomChip(parseInt(this.chipValue, 0))
        this.$emit('on-close')
    }

    checkBuyinLength(e: KeyboardEvent): void {
        if (e.code === 'KeyE') {
            e.preventDefault()
            return
        }

        if (this.chipValue.toString().length > 8 && e.code !== 'Backspace')
            e.preventDefault()
    }
}
