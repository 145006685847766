import { Prop } from 'vue-property-decorator'
import { Color } from '@/utils/constants'
import { Options, Vue } from 'vue-class-component'
import { RoadMapSummaryData } from '@/datamodel/types'

@Options({})
export class PredictionSumaryMixin extends Vue {
    @Prop({ default: Color.RED })
    color!: number
    @Prop({ default: '' })
    label!: string
    @Prop({ default: 1 })
    preType!: number

    @Prop({ default: true })
    isHorizontal!: boolean

    @Prop({ default: undefined })
    preData!: RoadMapSummaryData

    clicked(): void {
        this.$emit('onpredict', this.preType)
    }
    get hasBigEye(): boolean {
        if (!this.preData) return false
        const _d = this.preData.data
        if (_d) {
            return _d.bigeyes.length > 0
        }
        return false
    }

    get hasSmall(): boolean {
        if (!this.preData) return false
        const _d = this.preData.data
        if (_d) {
            return _d.smalls.length > 0
        }
        return false
    }
    get hasCockRoach(): boolean {
        if (!this.preData) return false
        const _d = this.preData.data
        if (_d) {
            return _d.cockroachs.length > 0
        }
        return false
    }

    get bigeyeColor(): string {
        if (!this.preData) return 'none'
        const _d = this.preData.data
        if (_d) {
            return _d.bigeyes[_d.bigeyes.length - 1].type === 0 ? 'blue' : 'red'
        }

        return 'none'
    }

    get smallColor(): string {
        if (!this.preData) return 'none'
        const _d = this.preData.data
        if (_d) {
            return _d.smalls[_d.smalls.length - 1].type === 0 ? 'blue' : 'red'
        }
        return 'none'
    }

    get cockRoachColor(): string {
        if (!this.preData) return 'none'
        const _d = this.preData.data
        if (_d) {
            return _d.cockroachs[_d.cockroachs.length - 1].type === 0
                ? 'blue'
                : 'red'
        }
        return 'none'
    }
}
