import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class DTBetParams extends BaseParams {
    sessionID: string
    username: string
    desk: number
    xian = 0
    long = 0
    hu = 0
    he = 0

    /**
     *
     */
    constructor(
        sid: string,
        uid: string,
        desk: number,
        long: number,
        hu: number,
        he: number,
        xian = 0
    ) {
        super(ACT.DO_DT_BET)
        this.sessionID = sid
        this.username = uid
        this.desk = desk
        this.long = long
        this.hu = hu
        this.he = he
        this.xian = xian
    }
}
