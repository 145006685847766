import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-switch-table-item-root" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "map-container" }
const _hoisted_4 = { class: "switch-limit-pop-content" }
const _hoisted_5 = { class: "switch-limit-pop-head" }
const _hoisted_6 = { class: "switch-limit-pop-list" }
const _hoisted_7 = { class: "switch-limit-pop-item-label" }
const _hoisted_8 = { class: "switch-imit-pop-item-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_road_map_view = _resolveComponent("road-map-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("span", null, _toDisplayString(_ctx.title), 1),
      _createVNode("span", null, "局数 " + _toDisplayString(_ctx.mdata.xue) + " - " + _toDisplayString(_ctx.mdata.ju), 1),
      _createVNode("span", {
        class: [_ctx.timerClass, "timer"]
      }, _toDisplayString(_ctx.currentTime !== -1 ? _ctx.currentTime : ''), 3)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_road_map_view, {
        class: "map",
        display: 3,
        mapData: _ctx.deskResult
      }, null, 8, ["mapData"]),
      (_ctx.isClicked && !_ctx.isSelected)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: "switch-limit-pop",
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode("div", _hoisted_4, [
              _createVNode("div", _hoisted_5, _toDisplayString(_ctx.iN('tables.selectlimit')), 1),
              _createVNode("div", _hoisted_6, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tableLimits, (item, index) => {
                  return (_openBlock(), _createBlock("div", {
                    class: "switch-limit-pop-list-item",
                    key: index,
                    onClick: ($event: any) => (_ctx.enterDesk(item))
                  }, [
                    _createVNode("span", _hoisted_7, _toDisplayString(_ctx.iN('tables.limit')), 1),
                    _createVNode("span", _hoisted_8, _toDisplayString(item.Min) + " - " + _toDisplayString(item.Max), 1)
                  ], 8, ["onClick"]))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isSelected)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: { 'flag-selected': _ctx.isSelected }
        }, _toDisplayString(_ctx.iN('tables.youatthistable')), 3))
      : _createCommentVNode("", true)
  ]))
}