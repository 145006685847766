import { DeskData } from '@/datamodel/deskdata'
import { GameResult } from '@/datamodel/game-result'
import { TableLimit } from '@/datamodel/types'
import { RESULT } from '@/utils/constants'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Options({})
export class BetViewBaseMixin extends Vue {
    //#region Vuex

    @Getter('betCoin') betCoin!: number
    @Getter('deskLimit') tableLimit!: TableLimit
    @Getter('sessionId') sid!: string
    @Getter('userId') uid!: string
    @Getter('currentDesk') desk!: DeskData
    @Getter('deskLimit') deskLimit!: TableLimit

    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('setBetCoin') setBetCoin!: (coin: number) => void

    //#endregion
    @Prop({ default: -1 }) chipValue!: number
    @Prop({ default: false }) allowBet!: boolean

    @Prop({ default: 0 }) redConfirmed!: number
    @Prop({ default: 0 }) blueConfrimed!: number
    @Prop({ default: 0 }) greenConfrimed!: number
    @Prop({ default: -1 }) deskId!: number

    selectedBetArea = -1

    redTemp = 0
    blueTemp = 0
    greenTemp = 0

    hasPendingRequest = false

    isGreen = false
    isRed = false
    isBlue = false

    OnCancelBetTemp(): void {
        //
    }

    ResetConfirmedTemp(): void {
        //
    }

    showResult(deskResult: GameResult | undefined): void {
        if (deskResult) {
            const { whoWin } = deskResult
            this.isRed = whoWin === RESULT.RED
            this.isBlue = whoWin === RESULT.BLUE
            this.isGreen = whoWin === RESULT.GREEN
        }
    }

    hideGameResult(): void {
        this.isRed = false
        this.isGreen = false
        this.isBlue = false
    }
}
