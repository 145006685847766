import { GameResult } from '@/datamodel/game-result'
import { GAME_TYPE, RESULT } from '@/utils/constants'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({})
export class CardResultMixin extends Vue {
    @Prop({ default: undefined }) gameResult!: GameResult
    @Prop({ default: false }) offSound!: boolean

    @Prop({ default: -1 })
    gameType!: number

    redTotal = 0
    blueTotal = 0
    redHand = new Array<string>()
    blueHand = new Array<string>()

    wonLabel = ''
    winnerLabel = ''
    winnerColor = ''

    redLabel = ''

    blueLabel = ''

    mounted(): void {
        if (this.gameResult === undefined) return
        const {
            redpair,
            bluePair,
            redTotal,
            blueTotal,
            redCard,
            blueCard,
            whoWin,
            gameType,
            won
        } = this.gameResult
        this.redTotal = redTotal
        this.blueTotal = blueTotal
        this.redHand = redCard
        this.blueHand = blueCard

        // set winner color

        // set winner label
        const winner = this.gameResult.whoWin
        if (this.gameResult.gameType === 1) {
            if (winner === 1) {
                this.winnerLabel = '庄赢'
            } else if (winner === 2) {
                this.winnerLabel = '和局'
            } else {
                this.winnerLabel = '闲赢'
            }
        } else {
            if (winner === 1) {
                this.winnerLabel = '龙赢'
            } else if (winner === 2) {
                this.winnerLabel = '和局'
            } else {
                this.winnerLabel = '虎赢'
            }
        }

        if (winner === 1) {
            this.winnerColor = 'banker'
        } else if (winner === 2) {
            this.winnerColor = 'tie'
        } else if (winner === 3) {
            this.winnerColor = 'player'
        }

        // set redLabel
        this.redLabel = this.gameResult.gameType === 1 ? '庄' : '龙'
        // set blue label
        this.blueLabel = this.gameResult.gameType === 1 ? '闲' : '虎'

        if (this.offSound) {
            if (won !== 0 && won > 0) this.wonLabel = `+${this.gameResult.won}`
            else if (won !== 0 && won < 0)
                this.wonLabel = `${this.gameResult.won}`
            return
        }

        if (gameType === GAME_TYPE.BACCARAT) {
            if (redpair && bluePair) {
                if (whoWin === RESULT.RED)
                    audioPlayer.Play(SOUNDKEY.BANKER_WIN_BOTH_PAIR)
                else if (whoWin === RESULT.BLUE)
                    audioPlayer.Play(SOUNDKEY.PLAYER_WIN_BOTH_PAIR)
                else if (whoWin === RESULT.GREEN)
                    audioPlayer.Play(SOUNDKEY.TIE_BOTH_PAIR)
            } else if (redpair && !bluePair) {
                if (whoWin === RESULT.RED)
                    audioPlayer.Play(SOUNDKEY.BANKER_WIN_BANKER_PAIR)
                else if (whoWin === RESULT.BLUE)
                    audioPlayer.Play(SOUNDKEY.PLAYER_WIN_BANKER_PAIR)
                else if (whoWin === RESULT.GREEN)
                    audioPlayer.Play(SOUNDKEY.TIE_BANKER_PAIR)
            } else if (!redpair && bluePair) {
                if (whoWin === RESULT.RED)
                    audioPlayer.Play(SOUNDKEY.BANKER_WIN_PLAYER_PAIR)
                else if (whoWin === RESULT.BLUE)
                    audioPlayer.Play(SOUNDKEY.PLAYER_WIN_PLAYER_PAIR)
                else if (whoWin === RESULT.GREEN)
                    audioPlayer.Play(SOUNDKEY.TIE_PLAYER_PAIR)
            } else {
                if (whoWin === RESULT.RED) audioPlayer.Play(SOUNDKEY.BANKER_WIN)
                else if (whoWin === RESULT.BLUE)
                    audioPlayer.Play(SOUNDKEY.PLAYER_WIN)
                else if (whoWin === RESULT.GREEN) audioPlayer.Play(SOUNDKEY.TIE)
            }
        } else if (gameType === GAME_TYPE.DRAGON_TIGER) {
            if (whoWin === RESULT.RED) audioPlayer.Play(SOUNDKEY.DRAGON_WIN)
            else if (whoWin === RESULT.BLUE)
                audioPlayer.Play(SOUNDKEY.TIGER_WIN)
            else if (whoWin === RESULT.GREEN) audioPlayer.Play(SOUNDKEY.TIE)
        }
    }

    unmounted(): void {
        this.blueTotal = 0
        this.redTotal = 0
        this.redHand = new Array<string>()
        this.blueHand = new Array<string>()
        this.wonLabel = ''
        this.winnerLabel = ''
        this.winnerColor = ''
        this.redLabel = ''
        this.blueLabel = ''
    }

    get blue1stCard(): string {
        return `/images/cards/${this.blueHand[0]}.png`
    }

    get blue2ndCard(): string {
        return `/images/cards/${this.blueHand[1]}.png`
    }

    get blue3rdCard(): string {
        return `/images/cards/${this.blueHand[2]}.png`
    }

    get red1stCard(): string {
        return `/images/cards/${this.redHand[0]}.png`
    }

    get red2ndCard(): string {
        return `/images/cards/${this.redHand[1]}.png`
    }

    get red3rdCard(): string {
        return `/images/cards/${this.redHand[2]}.png`
    }
}
