import { BET_TYPE } from '@/utils/constants'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AppSvg from '@/components/app-svg.vue'

@Options({
    components: {
        'app-svg': AppSvg
    }
})
export class BetAreaViewMixin extends Vue {
    @Prop({ default: '1:1' }) betRatio!: string
    @Prop({ default: -1 })
    betType!: number
    @Prop({ default: -1 })
    offSet!: number
    @Prop({ default: false })
    allowBet!: boolean

    @Prop({ default: -1 })
    currentIndex!: number
    @Prop({ default: 0 })
    tempAmount!: number
    @Prop({ default: 0 })
    confirmedAMount!: number

    @Prop({ default: -1 })
    chipValue!: number

    @Prop({ default: false })
    showFlash!: boolean

    handleBetClicked(): void {
        if (this.chipValue > 0 && this.allowBet)
            this.$emit('place-bet', this.betType)
    }

    cancelBet(): void {
        this.$emit('cancel-bet')
    }

    confirmBet(): void {
        this.$emit('on-confirm-bet')
    }

    get betLabel(): string {
        switch (this.betType) {
            case BET_TYPE.BANKER:
            case BET_TYPE.BANKER_SUPER6:
                return '庄'
            case BET_TYPE.BANKER_PAIR:
                return '庄对'
            case BET_TYPE.PLAYER:
                return '闲'
            case BET_TYPE.PLAYER_PAIR:
                return '闲对'
            case BET_TYPE.BC_TIE:
            case BET_TYPE.DT_TIE:
                return '和'
            case BET_TYPE.DRAGON:
                return '虎'
            case BET_TYPE.TIGER:
                return '龙'
            case BET_TYPE.SUPER6:
                return 'Super 6'
        }
        return ''
    }

    get color(): string {
        let result = ''
        switch (this.betType) {
            case BET_TYPE.BANKER:
            case BET_TYPE.TIGER:
            case BET_TYPE.BANKER_PAIR:
            case BET_TYPE.BANKER_SUPER6:
                result = 'red'
                break
            case BET_TYPE.PLAYER:
            case BET_TYPE.DRAGON:
            case BET_TYPE.PLAYER_PAIR:
                result = 'blue'
                break
            case BET_TYPE.BC_TIE:
            case BET_TYPE.DT_TIE:
                result = 'green'
                break
            case BET_TYPE.SUPER6:
                result = 'super6'
                break
        }

        if (!this.allowBet) {
            return result + '-blur'
        }
        return result
    }

    get amount(): string {
        return (this.tempAmount + this.confirmedAMount).toString()
    }

    get needToDisPlayAmount(): boolean {
        const total = this.tempAmount + this.confirmedAMount

        return total > 0
    }

    get hasUnConfirmedBet(): boolean {
        return this.tempAmount > 0
    }

    get hasNoBet(): boolean {
        return this.tempAmount + this.confirmedAMount <= 0
    }

    get hasBetConfirmedBet(): boolean {
        return this.confirmedAMount > 0
    }
}
