
import { store } from '@/store'
import { Device } from '@/store/types'
import { Getter } from 'vuex-class'
import { DeskData } from '@/datamodel/deskdata'
import { Options, Vue } from 'vue-class-component'
import { TableLimit } from '@/datamodel/types'
import { GAME_TYPE } from '@/utils/constants'

@Options({})
export default class LimitDialogView extends Vue {
    isMobile = false

    @Getter('device') device!: Device
    @Getter('deskLimit') deskLimit!: TableLimit
    @Getter('currentDesk') currentDesk!: DeskData

    beforeMount(): void {
        this.isMobile = this.device.display === 1
    }

    mounted(): void {
        console.log(this.deskLimit.Id)
    }

    closeDialog(): void {
        this.$emit('on-close')
    }

    redAndBlueLimit(): string {
        const _limit: TableLimit = store.getters.deskLimit
        if (_limit) {
            return ` ${_limit.Min} - ${_limit.Max}`
        }

        return '0 - 0'
    }

    greenLimit(): string {
        const _limit: TableLimit = store.getters.deskLimit
        if (_limit) {
            return ` ${_limit.TieMinBet} - ${_limit.TieMaxBet}`
        }

        return '0 - 0'
    }

    pairLimit(): string {
        const _limit: TableLimit = store.getters.deskLimit
        if (_limit) {
            return ` ${_limit.PairMinBet} - ${_limit.PairMaxBet}`
        }

        return '0 - 0'
    }

    get gameType(): number {
        if (this.currentDesk)
            return this.currentDesk.gameType || GAME_TYPE.BACCARAT
        else return GAME_TYPE.BACCARAT
    }
}
