import { DeskData } from '@/datamodel/deskdata'
import { ResponseData } from '@/datamodel/response-data'
// import { ResponseData } from '@/datamodel/responsedata'
import { MultipleDeskParams } from '@/datamodel/urlparams/MultipleDeskParams'
// import { EventBus, EventBusEvents } from '@/event/EventBus'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Options({})
export class SwitchTableMixin extends Vue {
    iN = useI18n().t
    //#region Properties
    selectedIndex = 1
    hasPendingRequest = false
    desks = new Array<DeskData>()
    serviceTimer: number | boolean = false
    countDownTimer: number | boolean = false

    isCurrentClicked = -1

    //#endregion
    @Prop({ default: false })
    show!: boolean

    @Prop({ default: true }) showSelected!: boolean

    //#region Vuex Action
    @Action('hideLoading') hideLoading!: () => void
    @Action('showLoading') showLoading!: () => void
    @Action('getMultipleDesk') getMultipleDesk!: (
        model: MultipleDeskParams
    ) => Promise<ResponseData>
    @Action('destroySession') destroySession!: () => void
    //#endregion

    //#region Vuex Getter
    // @Getter('loadingIsVisible') loadingIsVisible!: boolean
    @Getter('sessionId') sessionId!: string
    @Getter('userId') userId!: string

    //#endregion
    //#region Vue Methods
    mounted(): void {
        this.fetchData()
    }
    beforeUnmount(): void {
        this.stopService()
    }
    //#endregion
    //#region  Methods
    hideMe(): void {
        this.$emit('on-hide-switch-table')
    }

    changeIndex(num: number): void {
        if (num !== this.selectedIndex) {
            this.selectedIndex = num
        }
    }

    onSelectTable(desk: DeskData): void {
        // console.log(deskId)
        // EventBus.$emit(EventBusEvents.SWITCH_TABLE, desk)
        this.$emit('on-switch-table', desk)
    }

    clickedItem(desk: DeskData): void {
        if (this.isCurrentClicked === desk.deskId) {
            this.isCurrentClicked = -1
            return
        }

        this.isCurrentClicked = desk.deskId || -1
    }

    fetchData(): void {
        this.hasPendingRequest = true
        // if (!this.loadingIsVisible) this.showLoading()
        // EventBus.$emit(EventBusEvents.SHOW_LOADER, true)
        this.showLoading()
        const model = new MultipleDeskParams(3, this.sessionId, this.userId)

        this.getMultipleDesk(model)
            .then(response => {
                this.hideLoading()
                this.hasPendingRequest = false
                if (response.error) {
                    // EventBus.$emit(EventBusEvents.SHOW_LOADER, false)
                    if (response.error === '2') {
                        // session is expired logut user and redirect it to login
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    }
                } else {
                    // EventBus.$emit(EventBusEvents.SHOW_LOADER, false)

                    if (response.data) {
                        this.desks = DeskData.ParseList(response.data)
                    }
                    // start the service
                    this.startService()
                }
            })
            .catch(() => {
                // if (this.loadingIsVisible) this.hideLoading()
                // EventBus.$emit(EventBusEvents.SHOW_LOADER, false)
                this.hasPendingRequest = false
                // start service
                setTimeout(() => {
                    this.fetchData()
                }, 2000)
            })
    }

    stopService(): void {
        if (typeof this.serviceTimer === 'number') {
            clearInterval(this.serviceTimer)
            this.serviceTimer = false
        }
    }

    startService(): void {
        // stop service if service is running
        this.stopService()

        this.serviceTimer = setInterval(() => {
            const model = new MultipleDeskParams(3, this.sessionId, this.userId)

            this.getMultipleDesk(model)
                .then(response => {
                    this.hasPendingRequest = false
                    if (response.error) {
                        if (response.error === '2') {
                            // session is expired logut user and redirect it to login
                            this.destroySession()
                            this.$router.push({ name: 'login' })
                        }
                    } else {
                        if (response.data) {
                            this.desks = DeskData.ParseList(response.data)
                        }
                    }
                })
                .catch(() => {
                    this.hasPendingRequest = false
                })
        }, 2000)
    }

    //#endregion

    ////#region  Click Events
    onClickOutside(): void {
        this.$emit('close')
    }
    //#endregion

    //#region  Computed Properties
    get listData(): Array<DeskData> {
        if (this.selectedIndex === 1) {
            return this.desks.filter((desk: DeskData) => {
                return desk.gameType === 1
            })
        } else {
            return this.desks.filter((desk: DeskData) => {
                return desk.gameType === 2
            })
        }
    }

    //#endregion
}
