import { Prop, Watch } from 'vue-property-decorator'
import { DeskData } from '@/datamodel/deskdata'
import { Action, Getter } from 'vuex-class'
import RoadMapView from '@/components/roadmap/index.vue'
import { RoadMapData } from '@/components/roadmap/data-model'
import { DeskMoreInfoModel } from '@/datamodel/urlparams/DeskMoreInfoModel'
import { Options, Vue } from 'vue-class-component'
import { ResponseData } from '@/datamodel/response-data'
import { TableLimit } from '@/datamodel/types'
import { DeskMoreInfoData } from '@/datamodel/desk-more-info-data'
import { useI18n } from 'vue-i18n'

@Options({
    components: {
        RoadMapView
    }
})
export class SwitchTableItemMixin extends Vue {
    iN = useI18n().t
    roadMapData!: RoadMapData

    currentTime = -1
    deskTimer: number | boolean = false

    deskResult = ''

    @Prop() mdata!: DeskData
    @Prop({ default: false }) isClicked!: boolean

    @Prop({ default: true }) showSelected!: boolean

    @Getter('currentDesk') currentDesk!: DeskData
    @Getter('tableLimits') tableLimits!: Array<TableLimit>

    //#region Vuex Action
    @Action('getDeskOtherInfo') getDeskOtherInfo!: (
        model: DeskMoreInfoModel
    ) => Promise<ResponseData>
    @Action('destroySession') destroySession!: () => void
    @Action('setDeskLimit') setDeskLimit!: (limit: TableLimit) => void
    @Action('setDeskNotice') setDeskNotice!: (notice: string) => void
    @Action('setChipsValues') setChipsValues!: (
        chipvalues: Array<number>
    ) => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('showLoading') showLoading!: () => void

    // //#endregion

    beforeUnmount(): void {
        this.StopDeskTimer()
    }

    // onCliked(): void {
    //     if (this.mdata.deskId === this.currentDesk.deskId && this.showSelected)
    //         return
    //     // Show Loader
    //     this.showLoading()

    //     const model = new DeskMoreInfoModel(0)
    //     model.desk = this.mdata.deskId || 0

    //     this.getDeskOtherInfo(model)
    //         .then((response: ResponseData) => {
    //             this.hideLoading()
    //             if (response.error) {
    //                 if (response.error === '2') {
    //                     this.destroySession()
    //                     this.$router.push({ name: 'login' })
    //                 }
    //             } else {
    //                 const { notice } = DeskMoreInfoData.Parse(
    //                     response.data || ''
    //                 )

    //                 // const lm = limit
    //                 // if (lm) {
    //                 //     this.setDeskLimit(lm)
    //                 // }

    //                 // const chips = chipsVallue
    //                 // if (chips?.length === 0) {
    //                 //     chips = getChipListBaseOnMinBet(limit?.Min || 0)
    //                 // }

    //                 // if (chips) this.setChipsValues(chips)
    //                 if (notice) this.setDeskNotice(notice)

    //                 this.$emit('onSelectTable', this.mdata)
    //             }
    //         })
    //         .catch(() => {
    //             setTimeout(() => {
    //                 this.onCliked()
    //             }, 1000)
    //         })
    // }

    enterDesk(limit: TableLimit): void {
        this.showLoading()
        const model = new DeskMoreInfoModel(this.mdata.deskId || 0, limit.Id)

        this.getDeskOtherInfo(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error) {
                    if (response.error === '2') {
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                    }

                    this.hideLoading()
                } else {
                    const {
                        limit,
                        chipsVallue,
                        notice
                    } = DeskMoreInfoData.Parse(response.data || '')
                    const lm = limit

                    if (lm) {
                        lm.Id = model.xianId
                        this.setDeskLimit(lm)
                    }

                    if (chipsVallue) this.setChipsValues(chipsVallue)
                    if (notice) this.setDeskNotice(notice)

                    this.$emit('onSelectTable', this.mdata)

                    // this.setCurrentDesk(this.deskData)
                    // this.$router.push({ name: 'game' })
                }
            })
            .catch(() => {
                this.hideLoading()
            })
    }

    StopDeskTimer(): void {
        if (typeof this.deskTimer === 'number') {
            clearInterval(this.deskTimer)
            this.deskTimer = false
            this.currentTime = -1
        }
    }

    get timerClass(): string {
        if (this.currentTime > 20) {
            return 'green'
        } else if (this.currentTime <= 20 && this.currentTime > 10) {
            return 'orange'
        } else {
            return 'red'
        }
    }

    get title(): string {
        if (this.mdata.gameType === 1) {
            return `百家乐 ${this.mdata.deskId} 号桌`
        } else {
            return `龙虎 ${this.mdata.deskId} 号桌`
        }
    }

    get isSelected(): boolean {
        if (!this.currentDesk) return false
        return (
            this.currentDesk.deskId === this.mdata.deskId && this.showSelected
        )
    }

    @Watch('mdata', { immediate: true })
    OnDataChange(value: DeskData): void {
        if (value) {
            this.deskResult = value.result || ''

            if (value.currentTime > -1 && typeof this.deskTimer !== 'number') {
                this.currentTime = value.currentTime
                this.deskTimer = setInterval(() => {
                    if (this.currentTime !== -1) {
                        this.currentTime -= 1
                    } else {
                        this.StopDeskTimer()
                    }
                }, 1000)
            } else if (
                value.currentTime === -1 &&
                typeof this.deskTimer === 'number'
            ) {
                this.StopDeskTimer()
            }
        }
    }
}
