import { GameResult } from '@/datamodel/game-result'
import { ResponseData } from '@/datamodel/response-data'
import { PopAlertModel } from '@/datamodel/types'
import { BaccaratBetParams } from '@/datamodel/urlparams/BaccaratBetParams'
import { BetError, BET_TYPE, EventBuss, RESULT } from '@/utils/constants'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { BetViewBaseMixin } from './bet-view-mixin'

@Options({})
export class BaccaratBetViewMixin extends Mixins(BetViewBaseMixin) {
    redPairTemp = 0
    bluePairTemp = 0
    super6Temp = 0
    redSuper6Temp = 0

    redConfirmedTemp = 0
    blueConfirmedTemp = 0
    greenConfirmedTemp = 0
    redPairConfirmedTemp = 0
    bluePairConfrimedTemp = 0
    super6ConfimedTemp = 0
    redSuper6ConfirmedTemp = 0

    isRedPair = false
    isBluePair = false
    isSuper6 = false

    testAmount = 1000

    showMessage = false
    message = ''

    @Prop({ default: 0 }) redPairConfirmed!: number
    @Prop({ default: 0 }) bluePairConfirmed!: number
    @Prop({ default: false }) s6Mode!: boolean
    @Prop({ default: 0 }) super6Confirmed!: number
    @Prop({ default: 0 }) redSuper6Confimed!: number

    @Action('placeBaccaratBet') placeBaccaratBet!: (
        model: BaccaratBetParams
    ) => Promise<ResponseData>

    OnPlaceBet(betType: number): void {
        if (this.chipValue <= 0) return

        const toBetAmount = this.CheckIfValidBet(this.chipValue)
        if (toBetAmount > 0) {
            switch (betType) {
                case BET_TYPE.BANKER: {
                    this.redTemp += toBetAmount

                    const _rTotalR =
                        this.redTemp + this.redConfirmed + this.redConfirmedTemp
                    const _bTotalR =
                        this.blueTemp +
                        this.blueConfrimed +
                        this.blueConfirmedTemp

                    const _maxR = this.deskLimit.Max || 0

                    if (Math.abs(_rTotalR - _bTotalR) > _maxR) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)

                        this.redTemp = _bTotalR + _maxR
                    }

                    if (this.redTemp > 0) this.selectedBetArea = betType

                    break
                }
                case BET_TYPE.PLAYER: {
                    this.blueTemp += toBetAmount

                    const _rTotalB =
                        this.redTemp + this.redConfirmed + this.redConfirmedTemp
                    const _bTotalB =
                        this.blueTemp +
                        this.blueConfrimed +
                        this.blueConfirmedTemp

                    const _maxB = this.deskLimit.Max || 0

                    if (Math.abs(_rTotalB - _bTotalB) > _maxB) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)

                        this.blueTemp = _rTotalB + _maxB
                    }

                    if (this.blueTemp > 0) this.selectedBetArea = betType
                    break
                }
                case BET_TYPE.BC_TIE: {
                    this.greenTemp += toBetAmount

                    const _gTotal =
                        this.greenTemp +
                        this.greenConfrimed +
                        this.greenConfirmedTemp
                    const _gMax = this.deskLimit.TieMaxBet || 0

                    if (_gTotal > _gMax) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)
                        this.greenTemp =
                            _gMax -
                            this.greenConfirmedTemp -
                            this.greenConfrimed
                    }

                    if (this.greenTemp > 0) this.selectedBetArea = betType
                    break
                }
                case BET_TYPE.PLAYER_PAIR: {
                    this.bluePairTemp += toBetAmount

                    const _bPTotalConfirmed =
                        this.bluePairConfirmed + this.bluePairConfrimedTemp
                    const _rPTotalConfirmed =
                        this.redPairConfirmed + this.redPairConfirmedTemp

                    const _totalPTemp = this.redPairTemp + this.bluePairTemp

                    const _sPAllTotal =
                        _bPTotalConfirmed + _rPTotalConfirmed + _totalPTemp
                    const _pairMax = this.deskLimit.PairMaxBet as number

                    if (_sPAllTotal > _pairMax) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)
                        this.bluePairTemp =
                            _pairMax -
                            _bPTotalConfirmed -
                            _rPTotalConfirmed -
                            this.redPairTemp
                    }

                    if (this.bluePairTemp > 0) this.selectedBetArea = betType

                    break
                }
                case BET_TYPE.BANKER_PAIR: {
                    this.redPairTemp += toBetAmount

                    const _bPTotalConfirmed =
                        this.bluePairConfirmed + this.bluePairConfrimedTemp
                    const _rPTotalConfirmed =
                        this.redPairConfirmed + this.redPairConfirmedTemp

                    const _totalPTemp = this.redPairTemp + this.bluePairTemp

                    const _sPAllTotal =
                        _bPTotalConfirmed + _rPTotalConfirmed + _totalPTemp
                    const _pairMax = this.deskLimit.PairMaxBet as number

                    if (_sPAllTotal > _pairMax) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)
                        this.redPairTemp =
                            _pairMax -
                            _bPTotalConfirmed -
                            _rPTotalConfirmed -
                            this.bluePairTemp
                    }

                    if (this.redPairTemp > 0) this.selectedBetArea = betType

                    break
                }
                case BET_TYPE.SUPER6: {
                    this.super6Temp += toBetAmount

                    const _s6TotalConfirmed =
                        this.super6Confirmed + this.super6ConfimedTemp
                    const _s6Max = this.deskLimit.TieMaxBet || 0
                    const _s6AllTotal = _s6TotalConfirmed + this.super6Temp

                    if (_s6AllTotal > _s6Max) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)

                        this.super6Temp = _s6Max - _s6TotalConfirmed
                    }

                    if (this.super6Temp > 0) this.selectedBetArea = betType
                    break
                }
                case BET_TYPE.BANKER_SUPER6: {
                    this.redSuper6Temp += toBetAmount

                    const _rS6Total_s6 =
                        this.redSuper6Temp +
                        this.redSuper6Confimed +
                        this.redSuper6ConfirmedTemp
                    const _bTotal_s6 =
                        this.blueTemp +
                        this.blueConfrimed +
                        this.blueConfirmedTemp

                    const _max_s6 = this.deskLimit.Max || 0

                    if (Math.abs(_rS6Total_s6 - _bTotal_s6) > _max_s6) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)

                        this.redSuper6Temp = _bTotal_s6 + _max_s6
                    }

                    if (this.redSuper6Temp > 0) this.selectedBetArea = betType
                    break
                }
            }

            const totalTemp =
                this.redPairTemp +
                this.bluePairTemp +
                this.greenTemp +
                this.redTemp +
                this.blueTemp +
                this.super6Temp +
                this.redSuper6Temp
            this.$emit('on-bet-change', totalTemp)

            // check again
            // this.CheckIfValidBets()
        }
    }

    toastCommonError(_error: string): void {
        if (this.deskId !== -1) {
            this.PopMessage(_error)
        } else {
            const popError: PopAlertModel = {
                type: 0,
                header: '',
                message: _error,
                color: ''
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
        }
    }

    CheckIfValidBets(): void {
        const popError: PopAlertModel = {
            type: 0,
            header: '',
            message: '',
            color: ''
        }

        let betError = this.checkRedAndBlueLimit()

        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }

            // this.OnCancelBetTemp()
            return
        }

        betError = this.checkGreenLimit()

        if (betError !== BetError.BET_OK) {
            // console.log(betError)
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            // this.OnCancelBetTemp()
            return
        }

        const bluePairTotal = this.bluePairTemp + this.bluePairConfirmed

        betError = this.checkPairLimit(bluePairTotal, this.bluePairTemp)

        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            // this.OnCancelBetTemp()
            return
        }

        const redPairTotal = this.redPairTemp + this.redPairConfirmed

        betError = this.checkPairLimit(redPairTotal, this.redPairTemp)

        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            // this.OnCancelBetTemp()
            return
        }

        betError = this.checkSuper6Limit()
        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            // this.OnCancelBetTemp()
            return
        }
    }

    OnCancelBetTemp(): void {
        this.redTemp = 0
        this.blueTemp = 0
        this.greenTemp = 0
        this.bluePairTemp = 0
        this.redPairTemp = 0
        this.super6Temp = 0
        this.redSuper6Temp = 0
        this.selectedBetArea = -1

        this.$emit('on-bet-change', 0)
    }

    showResult(deskResult: GameResult | undefined): void {
        if (deskResult) {
            const { whoWin, redpair, bluePair, isSuper6 } = deskResult
            this.isBluePair = bluePair
            this.isRedPair = redpair
            this.isRed = whoWin === RESULT.RED
            this.isBlue = whoWin === RESULT.BLUE
            this.isGreen = whoWin === RESULT.GREEN
            this.isSuper6 = isSuper6
        }
    }

    hideGameResult(): void {
        this.isRedPair = false
        this.isBluePair = false
        this.isRed = false
        this.isGreen = false
        this.isBlue = false
        this.isSuper6 = false
    }

    CheckIfValidBet(tempBet: number): number {
        const total =
            this.redTemp +
            this.blueTemp +
            this.greenTemp +
            this.redPairTemp +
            this.bluePairTemp +
            tempBet

        if (total <= this.betCoin) return tempBet
        else {
            if (this.deskId !== -1) {
                this.PopMessage(BetError.BALANCE_SHORT)
            } else {
                const popError: PopAlertModel = {
                    type: 0,
                    header: '',
                    message: BetError.BALANCE_SHORT,
                    color: ''
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }

            const finalTemp = this.betCoin - (total - tempBet)
            if (finalTemp > 0) return finalTemp
            return 0
        }
    }

    OnConfirmBet(): void {
        const popError: PopAlertModel = {
            type: 0,
            header: '',
            message: '',
            color: ''
        }

        let betError = this.checkRedAndBlueLimit()

        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            return
        }

        betError = this.checkGreenLimit()

        if (betError !== BetError.BET_OK) {
            // console.log(betError)
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            return
        }

        const bluePairTotal = this.bluePairTemp + this.bluePairConfirmed

        betError = this.checkPairLimit(bluePairTotal, this.bluePairTemp)

        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            return
        }

        const redPairTotal = this.redPairTemp + this.redPairConfirmed

        betError = this.checkPairLimit(redPairTotal, this.redPairTemp)

        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            return
        }

        betError = this.checkSuper6Limit()
        if (betError !== BetError.BET_OK) {
            if (this.deskId !== -1) {
                this.PopMessage(betError)
            } else {
                popError.message = betError
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            return
        }

        // betError =
        // everything is passed here call bet
        this.sendBetRequest()
    }

    checkRedAndBlueLimit(): string {
        const redTotal = this.redTemp + this.redConfirmed
        const blueTotal = this.blueTemp + this.blueConfrimed

        if (
            (redTotal > 0 && redTotal < (this.tableLimit.Min as number)) ||
            (blueTotal > 0 && blueTotal < (this.tableLimit.Min as number))
        )
            return BetError.BELOW_MINIMUM

        const differenceTotal = Math.abs(redTotal - blueTotal)
        if (differenceTotal > (this.tableLimit.Max as number))
            return BetError.ABOVE_MAXIMUM

        return BetError.BET_OK
    }

    checkGreenLimit(): string {
        const greenTotal = this.greenTemp + this.greenConfrimed

        if (
            this.greenTemp > 0 &&
            greenTotal < (this.tableLimit.TieMinBet as number)
        )
            return BetError.BELOW_MINIMUM

        if (greenTotal > (this.tableLimit.TieMaxBet as number))
            return BetError.ABOVE_MAXIMUM
        return BetError.BET_OK
    }

    checkPairLimit(pairTotalBet: number, pairTemp: number): string {
        const _totalPairConfirmed =
            this.redPairConfirmed + this.bluePairConfirmed
        const _totalPairTemp = this.redPairTemp + this.bluePairTemp

        if (
            pairTemp > 0 &&
            pairTotalBet < (this.tableLimit.PairMinBet as number)
        )
            return BetError.BELOW_MINIMUM

        if (
            _totalPairConfirmed + _totalPairTemp >
            (this.tableLimit.PairMaxBet as number)
        )
            return BetError.ABOVE_MAXIMUM
        // if (pairTotalBet > (this.tableLimit.PairMaxBet as number))
        //     return BetError.ABOVE_MAXIMUM
        return BetError.BET_OK
    }

    checkSuper6Limit(): string {
        const super6Total = this.super6Temp + this.super6Confirmed

        if (
            this.super6Temp > 0 &&
            super6Total < (this.tableLimit.TieMinBet as number)
        )
            return BetError.BELOW_MINIMUM

        if (
            this.super6Temp > 0 &&
            super6Total > (this.tableLimit.TieMaxBet as number)
        )
            return BetError.ABOVE_MAXIMUM

        return BetError.BET_OK
    }

    sendBetRequest(): void {
        if (this.hasPendingRequest) return

        this.hasPendingRequest = true
        const popError: PopAlertModel = {
            type: 0,
            header: '',
            message: '',
            color: ''
        }

        let y_sz = 0
        let y_z = 0
        const y_x = this.blueTemp
        const y_zd = this.redPairTemp
        const y_xd = this.bluePairTemp
        const y_h = this.greenTemp
        let y_ss = 0

        if (this.s6Mode) {
            y_sz = this.redSuper6Temp
            y_ss = this.super6Temp
        } else {
            y_z = this.redTemp
        }

        let _deskId = this.deskId

        if (_deskId === -1) _deskId = this.desk.deskId || 0

        const model = new BaccaratBetParams(
            this.sid,
            this.uid,
            _deskId,
            this.tableLimit.Id || 0,
            y_sz,
            y_z,
            y_x,
            y_zd,
            y_xd,
            y_h,
            y_ss
        )

        this.showLoading()
        this.placeBaccaratBet(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                this.hasPendingRequest = false
                if (response.data) {
                    const arrInfo = response.data.split('#')

                    if (response.data.includes('xzok')) {
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                        this.setBetCoin(parseInt(arrInfo[1]))

                        if (this.deskId !== -1) {
                            this.PopMessage('下注成功')
                        } else {
                            popError.message = '下注成功'
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            this.emitter.emit(
                                EventBuss.SHOW_POP_ALERT,
                                popError
                            )
                        }

                        // add the tempbet to confirm bet

                        this.blueConfirmedTemp += this.blueTemp
                        this.greenConfirmedTemp += this.greenTemp
                        this.redPairConfirmedTemp += this.redPairTemp
                        this.bluePairConfrimedTemp += this.bluePairTemp
                        if (this.s6Mode) {
                            this.super6ConfimedTemp += this.super6Temp
                            this.redSuper6ConfirmedTemp += this.redSuper6Temp
                        } else {
                            this.redConfirmedTemp += this.redTemp
                        }

                        this.$emit('bet-success')
                        this.OnCancelBetTemp()
                    } else {
                        popError.message = arrInfo[1]

                        if (this.deskId !== -1) {
                            this.PopMessage(arrInfo[1])
                        } else {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            this.emitter.emit(
                                EventBuss.SHOW_POP_ALERT,
                                popError
                            )

                            // console.log(popError.message)
                        }
                    }
                }
            })
            .catch(err => {
                this.hideLoading()
                this.hasPendingRequest = false
                popError.message = err
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            })
    }

    ResetConfirmedTemp(): void {
        this.redConfirmedTemp = 0
        this.blueConfirmedTemp = 0
        this.greenConfirmedTemp = 0
        this.redPairConfirmedTemp = 0
        this.bluePairConfrimedTemp = 0
        this.redSuper6ConfirmedTemp = 0
        this.super6ConfimedTemp = 0
        this.hasPendingRequest = false
    }

    PopMessage(msg: string): void {
        this.message = msg
        this.showMessage = true

        setTimeout(() => {
            this.showMessage = false
            this.message = ''
        }, 1000)
    }

    get redFinal(): number {
        if (this.s6Mode)
            return this.redSuper6Confimed + this.redSuper6ConfirmedTemp
        return this.redConfirmed + this.redConfirmedTemp
    }

    get blueFinal(): number {
        return this.blueConfrimed + this.blueConfirmedTemp
    }

    get greenFinal(): number {
        return this.greenConfrimed + this.greenConfirmedTemp
    }

    get bluePairFinal(): number {
        return this.bluePairConfirmed + this.bluePairConfrimedTemp
    }

    get redPairFinal(): number {
        return this.redPairConfirmed + this.redPairConfirmedTemp
    }

    get super6Final(): number {
        return this.super6Confirmed + this.super6ConfimedTemp
    }
}
