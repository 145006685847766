import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class BaccaratBetParams extends BaseParams {
    sessionID: string
    username: string
    desk: number
    xian = 0
    y_sz = 0
    y_z = 0
    y_x = 0
    y_zd = 0
    y_xd = 0
    y_h = 0
    y_ss = 0

    /**
     *
     */
    constructor(
        sid: string,
        uid: string,
        desk: number,
        xian = 0,
        y_sz: number,
        y_z: number,
        y_x: number,
        y_zd: number,
        y_xd: number,
        y_h: number,
        y_ss: number
    ) {
        super(ACT.DO_BAC_BET)
        this.sessionID = sid
        this.username = uid
        this.desk = desk
        this.xian = xian
        this.y_sz = y_sz
        this.y_z = y_z
        this.y_x = y_x
        this.y_zd = y_zd
        this.y_xd = y_xd
        this.y_h = y_h
        this.y_ss = y_ss
    }
}
