export function getCardFaceValue(code: string): number {
    if (code.length !== 4) return 0
    return parseInt(code.substr(1, 2))
}

export function getBaccaratValue(code: string): number {
    if (code.length !== 4) return 0

    const value = parseInt(code.substr(1, 2))

    if (value >= 10) return 0
    return value
}

/**
 * Get Which Card has a higher value when they have the same card face value
 * @param code
 */
export function getDTFaceValue(code: string): number {
    if (code.length !== 4) return 0
    return parseInt(code.substr(0, 1))
}
